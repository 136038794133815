import genericItem from './genericItem'
import dataTableHelper from '../../utils/dataTableHelper'

const base = genericItem('companies', 'company')

export default {
  namespaced: true,
  actions: {
    ...base.actions,
    async fetchUsers({ rootGetters }, company, options) {
      const apitator = rootGetters.apitatorInstance

      return await dataTableHelper.fetchData(
        apitator, `/companies/${company.id}/users`, options
      )
    },
  }
}
