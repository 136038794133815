import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";
//const URL = process.env.VUE_APP_API_BASE;

import localStorageHelper from "../utils/localStorage.js";

import alert from "./modules/alert.js";
import auth from "./modules/auth.js";

import genericItem from "./modules/genericItem";
import wasteTypes from "./modules/wasteTypes";
import companies from "./modules/companies";
import audits from "./modules/audits";
import elevators from "./modules/elevators";
import containerCycles from "./modules/containerCycles";
import products from "./modules/products";
import productCategories from "./modules/productCategories";
import productSheets from "./modules/productSheets";
import shafteeAccount from "./modules/shafteeAccount";
import shafteeApi from "./modules/shafteeApi";
import auditAnswers from "./modules/auditAnswers";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiBaseUrl: process.env.VUE_APP_API_BASE,
    apitatorInstance: null,
    debugMode: false,
    title: "",
  },
  mutations: {
    setApitatorInstance(state, apitatorInstance) {
      state.apitatorInstance = apitatorInstance;
    },
    setDebugMode(state, debugMode) {
      state.debugMode = debugMode;
      localStorageHelper.set("debug_mode", debugMode);
    },
    setTheme(state, theme) {
      state.theme = theme;
      localStorageHelper.set("theme", theme);
    },
    setTitle(state, title) {
      state.title = title;
      document.title = title + " | Tracklift";
    },
  },
  actions: {
    init({ commit }) {
      // get debug mode from local storage
      commit("setDebugMode", localStorageHelper.get("debug_mode"));
      commit("setTheme", localStorageHelper.get("theme"));
    },
  },
  modules: {
    auth,
    alert,
    companies,
    users: genericItem("users"),
    sites: genericItem("sites"),
    elevators,
    containers: genericItem("containers"),
    containerCycles,
    constructors: genericItem("constructors"),
    audits,
    wasteTypes,
    theoreticalWastes: genericItem("theoretical_wastes", "theoretical_waste"),
    archives: genericItem("archives"),
    products,
    productCategories,
    productSheets,
    shafteeAccount,
    shafteeApi,
    auditAnswers,
  },
  getters: {
    apiBaseUrl: (state) => state.apiBaseUrl,
    apitatorInstance: (state) => state.apitatorInstance,
    debugMode: (state) => state.debugMode,
    theme: (state) => state.theme,
  },
});
