<template>
  <v-container>
    <!-- Create a welcome message centered horizontally and vertically -->
    <v-row justify="center">
      <v-col cols="12" md="5">
        <v-card>
          <v-card-title>
            <span class="headline text-break">
              Bienvenue sur tracklift !
            </span>
          </v-card-title>
          <v-card-text>
            <p>
              Tracklift est l'extranet de socobat environnement, qui vous permet d'organiser les projets de rénovation ou de déconstruction impliquant des ascenseurs.
            </p>
            <p class="mb-0">
              Bon usage !
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
       v-if="false && $store.state.auth.user.isAdmin"
       cols="12" md="5" lg="3"
      >
        <Statistics />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Statistics from '@/components/Statistics'

export default {
  components: { Statistics },
  created () {
    if (!this.$store.state.auth.user.isAdmin) {
      this.$router.replace({ name: 'Sites' })
    }
  }
}
</script>