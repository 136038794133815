import genericItem from "./genericItem";
import dataTableHelper from "../../utils/dataTableHelper";

const base = genericItem("products", "product");

export default {
  namespaced: true,
  state: () => ({
    nbProductsToEdit: 0,
  }),
  mutations: {
    setNbProductsToEdit(state, nbProductsToEdit) {
      state.nbProductsToEdit = nbProductsToEdit;
    },
  },
  actions: {
    ...base.actions,
    async createProduct({ rootGetters }, item) {
      const apitator = rootGetters.apitatorInstance;

      const response = await apitator.post(`/product`, item, {
        withAuth: true,
        params: item,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=<calculated when request is sent>",
          Accept: "*/*",
        },
      });

      return response.data.id ?? true;
    },
    async registerProducts({ rootGetters }, item) {
      const apitator = rootGetters.apitatorInstance;

      const response = await apitator.post(
        `/products/multiple`,
        item.formData,
        {
          withAuth: true,
          params: item,
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
            Accept: "*/*",
          },
        }
      );

      return response.data.id ?? true;
    },
    async updateProduct({ rootGetters }, params) {
      const apitator = rootGetters.apitatorInstance;
      const response = await apitator.post(
        `/update/product/${params.id}`,
        params.formData,
        {
          withAuth: true,
          params: params.formData,
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
            Accept: "*/*",
          },
        }
      );

      return response.data.id ?? true;
    },
    async countNewProducts({ rootGetters }, params) {
      const apitator = rootGetters.apitatorInstance;
      const response = await apitator.get(`/products/count`, {
        withAuth: true,
        params,
      });

      return response.data;
    },
    async getProductByAudit({ rootGetters }, auditId) {
      const apitator = rootGetters.apitatorInstance;
      const response = await apitator.get(`/products?audit=${auditId}`, {
        withAuth: true,
      });
      return response.data;
    },
    async deleteProductsByAudit({ rootGetters }, auditId) {
      const apitator = rootGetters.apitatorInstance;

      const response = await apitator.delete(`/products/audit/${auditId}`, {
        withAuth: true,
      });
      return response.data;
    },
  },
};
