import genericItem from './genericItem'

const base = genericItem('container_cycles', 'container_cycle')

export default {
  namespaced: true,
  actions: {
    ...base.actions,
    async newCycle({ rootGetters }, container) {
      const apitator = rootGetters.apitatorInstance

      const res = await apitator.post(
        `/containers/${container.id}/cycles`,
        {},
        { withAuth: true }
      )
      console.debug(`--> Created new cycle`)
      return res.data
    },
    async removeDocument({ rootGetters }, cycle) {
      const apitator = rootGetters.apitatorInstance

      const res = await apitator.delete(
        `/container_cycles/${cycle.id}/document`,
        { withAuth: true }
      )
      console.debug(`--> Deleted cycle document`)
      return res.data
    },
    async generateReport({ rootGetters }, cycle) {
      const apitator = rootGetters.apitatorInstance

      const res = await apitator.delete(
          `/container_cycles/${cycle.id}/report`,
          { withAuth: true }
      )
      console.debug(`--> Generated container cycle report`)
      return res.data
    },
  }
}
