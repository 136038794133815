import jwt_decode from 'jwt-decode'
import localStorageHelper from '../../utils/localStorage'

export default {
    namespaced: true,
    state: () => ({
        isInitialized: false,
        user: null,
        token: null,
        isAuthenticated: false,
    }),

    getters: {
        isAuthenticated: state => state.isAuthenticated,
    },

    actions: {
        async init(context) {
            let { commit, dispatch, rootGetters } = context
            console.debug('auth/init')
            const token = localStorageHelper.get('jwt')
            let user = null
            if (token !== null) {
                const apitator = rootGetters.apitatorInstance

                commit('setToken', token)
                apitator.setAuthorizationToken(token)

                // decode the JWT token
                const decoded = jwt_decode(token)

                // fetch the user
                user = await dispatch('users/fetchOne', decoded.user.id, { root: true })
                console.debug('--> Got user', JSON.parse(JSON.stringify(user)))

                commit('setUser', user)

                //commit('setUser', decoded.user)
                commit('setIsAuthenticated', true)

                console.debug('--> Logged in with id: ' + decoded.user.id)
            }
            commit('setIsInitialized', true)
        },
        async login({ commit, dispatch, rootGetters }, { email, password }) {
            const apitator = rootGetters.apitatorInstance

            try {
                const response = await apitator.post('/auth/login', { login: email, password })
                console.debug('--> Got token', response.data.token)

                commit('setToken', response.data.token)

                dispatch('init')
                return true
            } catch (error) {
                throw error
            }
        },
        logout({ commit }) {
            console.debug('auth/logout')
            commit('setUser', null)
            commit('setIsAuthenticated', false)
            commit('setToken', null)
            localStorageHelper.remove('jwt')
        }
    },

    mutations: {
        setToken(state, token) {
            // save the JWT to local storage
            localStorageHelper.set('jwt', token)
            state.token = token
        },
        setUser(state, user) {
            state.user = user
        },
        setIsAuthenticated(state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated
        },
        setIsInitialized(state, isInitialized) {
            state.isInitialized = isInitialized
        }

    },
}
