<template>
  <div v-if="$store.state.auth.isAuthenticated">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <div class="d-flex flex-column" style="height: 100%">
        <img
          class="mx-8 mt-5"
          src="../assets/images/logo_tracklift_full.svg"
          @click="$router.push({ name: 'Dashboard' })"
          style="cursor: pointer"
          alt="Logo tracklift"
        />
        <div style="flex-grow: 1; display: flex; align-items: center;" class="icon-specials">
          <v-list style="width: 100%">
            <v-list-item
              v-if="!$store.state.auth.user.isAdmin"
              ripple exact
              :to="{ name: 'ViewUserCompany' }"
            >
              <v-list-item-action>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Mon entreprise</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item ripple exact :to="{ name: 'Sites' }">
              <v-list-item-action>
                <v-icon>mdi-hammer-screwdriver</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Chantiers</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="$route.name !== 'Catalog/Cards'" ripple exact :to="{ name: 'Catalog/Table' }">
              <v-list-item-action>
                  <v-icon>mdi-ballot</v-icon>
                  <!-- This badge display the number of products with the property new to true -->
                  <v-badge
                    v-show="$store.state.products.nbProductsToEdit > 0"
                    style="position: absolute; left: 40px; z-index: 5;"
                    :content="$store.state.products.nbProductsToEdit"
                    small
                    color="primary"
                    overlap
                  >
                  </v-badge>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Catalogue</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            
            <v-list-item v-if="$route.name === 'Catalog/Cards'" ripple exact :to="{ name: 'Catalog/Cards' }">
              <v-list-item-action>
                  <v-icon>mdi-ballot</v-icon>
                  <!-- This badge display the number of products with the property new to true -->
                  <v-badge
                    v-show="$store.state.products.nbProductsToEdit > 0"
                    style="position: absolute; left: 40px; z-index: 5;"
                    :content="$store.state.products.nbProductsToEdit"
                    small
                    color="primary"
                    overlap
                  >
                  </v-badge>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Catalogue</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="$store.state.auth.user.isAdmin"
              ripple exact :to="{ name: 'Containers' }"
            >
              <v-list-item-action>
                <v-icon>mdi-trash-can</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Bennes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
<!--            <v-list-item ripple exact :to="{ name: 'Wastes' }">-->
<!--              <v-list-item-action>-->
<!--                <v-icon>mdi-widgets</v-icon>-->
<!--              </v-list-item-action>-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title>Déchets</v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--            </v-list-item>-->
            <v-list-item
              v-if="$store.state.auth.user.isAdmin"
              ripple exact :to="{ name: 'WasteTypes' }"
            >
              <v-list-item-action>
                <v-icon>mdi-tag-text</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Types de déchets</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="$store.state.auth.user.isAdmin"
              ripple exact :to="{ name: 'Companies' }"
            >
              <v-list-item-action>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Entreprises</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item ripple exact :to="{ name: 'Users' }">
              <v-list-item-action>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Utilisateurs</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item ripple exact :to="{ name: 'Elevator' }">
              <v-list-item-action>
                <v-icon>mdi-elevator</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>ascenseur</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>

        </div>
        <div class="ma-4">
          <v-btn
            color="accent" class="mb-3" block
            exact
            :to="{ name: 'Account' }"
          >
            <v-icon left>mdi-account</v-icon> Mon compte
          </v-btn>
          <v-btn
            text outlined block
            exact
            :to="{ name: 'Logout' }"
          >
            <v-icon left>mdi-logout</v-icon> Se déconnecter
          </v-btn>
        </div>
      </div>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>{{ $store.state.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import products from "./../utils/products"

export default {
  data: () => ({
    drawer: null,
  }),
  mounted () {
  },
  watch: {
    '$store.state.auth.user.isAdmin': function() {
      if(this.$store.state.auth.user.isAdmin){
        products.countNewProducts(this.$store);
      }
    }
  },
  methods:{
  }
}
</script>

<style>
  .icon-specials .v-icon {
    margin-right: 0 !important;
  }
</style>
