import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import VueApitator from 'vue-apitator'

import filters from './utils/filters'

import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import './registerServiceWorker'

Vue.use(VueApitator, {
  baseUrl: process.env.VUE_APP_API_BASE,
  globalCallbackOnError: (err) => {
    console.error('apitator global error', err)
    store.commit('alert/add', {
      color: 'error',
      text: err.message
    })
  }
})

Vue.config.productionTip = false

if (process.env.VUE_APP_SENTRY_ENABLE == 'true') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_ORIGIN, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true,
  })
  console.log('Sentry enabled')
}

new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    
  },
  render: (h) => h(App),
}).$mount("#app")
