import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import fr from 'vuetify/lib/locale/fr'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr'
  },
  theme: {
    themes: {
      // light: {
      //   primary: '#356859',
      //   secondary: '#FD5523',
      //   accent: '#37966F',
      //   tertiary: '#B9E4C9',
      // },
      dark: {
        secondary: '#94c9b9',
        accent: '#058D36',
        primary: '#058D36'
      },
      light: {
        accent: '#058D36',
        primary: '#058D36'
      }
      // dark: {
      //   primary: "#2A5347",
      //   secondary: "#94c9b9",
      //   accent: "#37966F",
      //   error: "#FF5252",
      //   info: "#2196F3",
      //   success: "#4CAF50",
      //   warning: "#FB8C00",
      //   tertiary: "#B9E4C9"
      // },
    },
  },
})
