import axios from "axios";

export default {
  namespaced: true,
  state: {
    url: 'https://back.shaftee.com/api/v1'
  },
  actions: {
    async getProduct({commit, state}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.url}/catalog/products/${params.productId}`, {
          headers: {
            Authorization: `token ${params.apiKey}`
          }
        }).then((res) => {
          resolve(res.data);
        }).catch(() => {
          reject();
        })
      });
    },
    async uploadProduct({commit, state}, params){
      return new Promise((resolve, reject) => axios.post(state.url + '/products',
        params.product,
        {
          params: params.product,
          headers: {
            Authorization: `token ${params.apiKey}`
          }
        }
      ).then((res) => {
        resolve(res.data);
      }).catch(() => {
        reject();
      }))
    },
    async getUser({commit, state}, params){
      return new Promise(resolve => axios.get(state.url + '/users/' + params.idShaftee,
      {
        headers: {
          Authorization: `token ${params.apiKey}`
        }
      }).then((res) => {
        resolve(res)
      }).catch(() => {
        this.commit('alert/add', {
          color: 'error',
          text: "La récupération des données fournis par Shaftee est impossible"
        })
      })).then((res) => {
        return res.data;
      })
    },
  }
}
