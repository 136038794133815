const ROLES = [
  { id: 'ROLE_ADMIN', color: 'red', name: 'Administrateur' },
  { id: 'ROLE_USER', color: 'green', name: 'Utilisateur' },
]

export default {
  getRoles: () => ROLES,
  getRole: id => {
    const res = ROLES.find(role => role.id === id)
    if (!res) {
      throw new Error('Role not found')
    }
    return res
  },
  isAdmin (state) {
    if (state.auth.user == null) {
      return false
    }
    return state.auth.user.roles.indexOf('ROLE_ADMIN') !== -1
  }
}
