<template>
  <!-- Display a centered loading spinner -->
  <v-container>
    <v-layout justify-center align-center>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  mounted () {
    this.refresh()
  },
  methods: {
    refresh () {
      if (!this.$store.state.auth.isInitialized) {
        return
      }
      if (!this.$store.state.auth.isAuthenticated) {
        this.$router.push({ name: 'Login' })
        return
      }

      this.$router.push({ name: 'Dashboard' })
    }
  },
  watch: {
    '$store.state.auth.isAuthenticated' (val) {
      this.refresh()
    }
  }
}
</script>
