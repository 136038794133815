export default {
  namespaced: true,

  state: () => ({
    interface: null
  }),
  
  mutations: {
    setInterface(state, instance) {
      state.interface = instance
    },
    add(state, params) {
      let color = params.color ?? params.type
      let text = params.text ?? params.message
      let timeout = params.timeout

      state.interface.add({
        color,
        text,
        timeout
      })
    },
    clear(state) {
      state.interface.clear()
    }
  },
}
