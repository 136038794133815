import roles from './roles'
import Vue from 'vue'
import { DateTime } from 'luxon'
import { availableCompanyTypes } from './constants'

Vue.filter('capitalize', subject => {
    if (!subject) return ''
    subject = subject.toString()
    return subject.charAt(0).toUpperCase() + subject.slice(1)
})

const formats = {
    'datetime': 'd LLL yyyy à HH:mm',
    'full_datetime': 'F',
    'date': 'DD',
    'date_full': 'DDDD'
}

Vue.filter('formatDate', (date, format = 'datetime') => {
    if (!date) return ''
    return DateTime
        .fromISO(date)
        .setLocale('fr')
        .toFormat(formats[format] ?? format)
})

Vue.filter('formatAddress', (address) => {
    let text = address.firstLine
    text += address.secondLine ? ', ' + address.secondLine : ''
    text += address.city ? ', ' + address.postalCode + ' ' + address.city : ''
    return text
})

Vue.filter('prettyCompanyType', (type) => {
    let res = availableCompanyTypes.find(t => t.id === type)
    return res ? res.name : type
})

Vue.filter('companyTypeIcon', (type) => {
    let res = availableCompanyTypes.find(t => t.id === type)
    return res ? res.icon : type
})

Vue.filter('prettyRoleName', roleId => {
    return roles.getRole(roleId).name
})

Vue.filter('truncate', (text, length = 50, suffix = '...') => {
    if (!text) return ''
    return text.length > length ? text.substring(0, length) + suffix : text
})

Vue.filter('withLeadingZeros', (number, length = 2) => {
    if (!number) return ''
    return ('0'.repeat(length) + number).slice(-length)
})

Vue.filter('truncateWithBreakpoint', (text, breakpoint, length = 50, suffix = '...') => {
    if (!breakpoint) {
        return text
    }
    if (!text) return ''
    return text.length > length ? text.substring(0, length) + suffix : text
})
