import genericItem from './genericItem'

const base = genericItem('product_categories', 'product_categories')

export default {
  namespaced: true,
  actions: {
    ...base.actions,
  }
}
