import genericItem from "./genericItem";

const base = genericItem("elevators", "elevator");

export default {
  namespaced: true,
  state: () => ({
    payload: [100, 150, 225, 300, 500, 630, 1000],
  }),
  actions: {
    ...base.actions,
    async generateHandlingCertificate({ rootGetters }, { elevator, type }) {
      const apitator = rootGetters.apitatorInstance;

      const res = await apitator.delete(
        `/elevators/${elevator.id}/handling_certificate/${type.id}`,
        { withAuth: true }
      );
      console.debug(`--> Generated handling certificate`);
      return res.data;
    },
    async removeExtraDocument({ rootGetters }, { elevator, extraDocument }) {
      const apitator = rootGetters.apitatorInstance;

      const res = await apitator.delete(
        `/elevators/${elevator.id}/documents/${extraDocument.id}`,
        { withAuth: true }
      );
      console.debug(`---> Removed extra document`);
      return res.data;
    },
  },
};
