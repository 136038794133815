import genericItem from './genericItem'
import dataTableHelper from '../../utils/dataTableHelper'

const base = genericItem('shaftee_accounts', 'shaftee_account')

export default {
  namespaced: true,
  actions: {
    ...base.actions,
    async fetchByUser({ rootGetters }, item){
      const apitator = rootGetters.apitatorInstance

      const response = await apitator.get(`/shaftee_accounts?user=${item}`, { withAuth: true });

      return response;
    },
    async registerApiKey({ rootGetters }, item){
      const apitator = rootGetters.apitatorInstance
      const response = await apitator.post(`/shaftee_accounts/register`, item, { withAuth: true, params: item, headers: { "Content-Type": 'multipart/form-data; boundary=<calculated when request is sent>', 'Accept': '*/*' }});

      return response;
    },
    async removeByIdShaftee({ rootGetters }, item){
      console.log(item)
      const apitator = rootGetters.apitatorInstance
      const response = await apitator.get(`/shaftee_accounts/remove/${item.idShaftee}`, { withAuth: true });

      return response;
    },
  }
}
