import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "CenterLayout",
      title: "",
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "logout" */ "../views/Logout.vue"),
    meta: {
      layout: "CenterLayout",
      title: "Déconnexion...",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "DashboardLayout",
      title: "Dashboard",
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users/Users.vue"),
    meta: {
      layout: "DashboardLayout",
      title: "Gestion des utilisateurs",
    },
  },
  {
    path: "/catalog/cards",
    name: "Catalog/Cards",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Catalog/Catalog.vue"),
    meta: {
      layout: "DashboardLayout",
      title: "Gestion du catalogue",
    },
  },
  {
    path: "/elevator",
    name: "Elevator",
    component: () => import(/* webpackChunkName: "users" */ "../views/Elevators/ElevatorDashbord.vue"),
    meta: {
      layout: 'DashboardLayout',
      title: 'ascenceur'
    }
  },
  {
    path: "/catalog/table",
    name: "Catalog/Table",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Catalog/Catalog.vue"),
    meta: {
      layout: "DashboardLayout",
      title: "Gestion du catalogue",
    },
  },
  {
    path: "/companies",
    name: "Companies",
    component: () =>
      import(
        /* webpackChunkName: "companies" */ "../views/Companies/Companies.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      title: "Gestion des entreprises",
    },
  },
  {
    path: "/my-company",
    name: "ViewUserCompany",
    component: () =>
      import(
        /* webpackChunkName: "ViewUserCompany" */ "../views/Companies/ViewUserCompany.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      title: "Mon entreprise",
    },
  },
  {
    path: "/companies/:id",
    name: "ViewCompany",
    component: () =>
      import(
        /* webpackChunkName: "view_company" */ "../views/Companies/ViewCompany.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      title: "Détails d'une entreprise",
    },
    children: [
      {
        path: "users",
        name: "CompanyUsers",
        component: () =>
          import(
            /* webpackChunkName: "company_users" */ "../views/Companies/CompanyUsers.vue"
          ),
        meta: {
          layout: "DashboardLayout",
          title: "Utilisateurs associés à une entreprise",
        },
      },
      {
        path: "sites",
        name: "CompanySites",
        component: () =>
          import(
            /* webpackChunkName: "company_sites" */ "../views/Companies/CompanySites.vue"
          ),
        meta: {
          layout: "DashboardLayout",
          title: "Chantiers associés à une entreprise",
        },
      },
    ],
    props: true,
  },
  {
    path: "/sites",
    name: "Sites",
    component: () =>
      import(/* webpackChunkName: "sites" */ "../views/Sites/Sites.vue"),
    meta: {
      layout: "DashboardLayout",
      title: "Gestion des chantiers",
    },
  },
  {
    path: "/sites/:id",
    name: "ViewSite",
    component: () =>
      import(/* webpackChunkName: "view_site" */ "../views/Sites/ViewSite.vue"),
    meta: {
      layout: "DashboardLayout",
      title: "Détails d'un chantier",
    },
    children: [
      {
        path: "elevators",
        name: "SiteElevators",
        component: () =>
          import(
            /* webpackChunkName: "site_elevators" */ "../views/Sites/SiteElevators.vue"
          ),
        meta: {
          layout: "DashboardLayout",
          title: "Ascenseurs associés à un chantier",
        },
      },
      {
        path: "details",
        name: "SiteDetails",
        component: () =>
          import(
            /* webpackChunkName: "site_details" */ "../views/Sites/SiteDetails.vue"
          ),
        meta: {
          layout: "DashboardLayout",
          title: "Détails d'un chantier",
        },
      },
    ],
    props: true,
  },
  {
    path: "/elevators/:id",
    name: "ViewElevator",
    component: () =>
      import(
        /* webpackChunkName: "view_elevator" */ "../views/Elevators/ViewElevator.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      title: "Détails d'un ascenseur",
    },
    children: [
      {
        path: "unloading",
        name: "ElevatorUnloading",
        component: () =>
          import(
            /* webpackChunkName: "elevator_unloading" */ "../views/Elevators/ElevatorUnloading.vue"
          ),
        meta: {
          layout: "DashboardLayout",
          title: "Remplissage d'un ascenseur",
        },
      },
      {
        path: "audit",
        name: "ElevatorAudit",
        component: () =>
          import(
            /* webpackChunkName: "elevator_audit" */ "../views/Elevators/ElevatorAudit.vue"
          ),
        meta: {
          layout: "DashboardLayout",
          title: "Audit d'un ascenseur",
        },
      },
      {
        path: "documents",
        name: "ElevatorDocuments",
        component: () =>
          import(
            /* webpackChunkName: "elevator_documents" */ "../views/Elevators/ElevatorDocuments.vue"
          ),
        meta: {
          layout: "DashboardLayout",
          title: "RSE d'un ascenseur",
        },
      },
      {
        path: "certificates",
        name: "ElevatorCertificates",
        component: () =>
          import(
            /* webpackChunkName: "elevator_certificates" */ "../views/Elevators/ElevatorCertificates.vue"
          ),
        meta: {
          layout: "DashboardLayout",
          title: "Attestations de collecte d'un ascenseur",
        },
      },
    ],
    props: true,
  },
  {
    path: "/elevator/:id/audit/edit/:idAudit",
    name: "EditAudit",
    component: () =>
      import(
        /* webpackChunkName: "audit_create" */ "../views/Audits/EditAudit.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      title: "Edition audit",
    },
  },
  {
    path: "/elevator/:id/audit/create",
    name: "CreateAudit",
    component: () =>
      import(
        /* webpackChunkName: "audit_create" */ "../views/Audits/CreateAudit.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      title: "Nouveau audit",
    },
  },
  {
    path: "/containers",
    name: "Containers",
    component: () =>
      import(
        /* webpackChunkName: "containers" */ "../views/Containers/Containers.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      title: "Gestion des bennes",
    },
  },
  {
    path: "/containers/:id",
    name: "ViewContainer",
    component: () =>
      import(
        /* webpackChunkName: "view_container" */ "../views/Containers/ViewContainer.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      title: "Gestion d'une benne",
    },
    children: [
      {
        path: "cycles",
        name: "ContainerCycles",
        component: () =>
          import(
            /* webpackChunkName: "container_container_cycles" */ "../views/Containers/ContainerCycles.vue"
          ),
        meta: {
          layout: "DashboardLayout",
          title: "Gestion d'une benne",
        },
      },
    ],
  },
  {
    path: "/wastes",
    name: "Wastes",
    component: () =>
      import(/* webpackChunkName: "wastes" */ "../views/Wastes/Wastes.vue"),
    meta: {
      layout: "DashboardLayout",
      title: "Déchets",
    },
  },
  {
    path: "/waste-types",
    name: "WasteTypes",
    component: () =>
      import(
        /* webpackChunkName: "waste_types" */ "../views/WasteTypes/WasteTypes.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      title: "Gestion des types de déchets",
    },
  },
  {
    path: "/waste-types/:id",
    name: "ViewWasteType",
    component: () =>
      import(
        /* webpackChunkName: "view_waste_type" */ "../views/WasteTypes/ViewWasteType.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      title: "Gestion d'un type de déchets",
    },
  },
  {
    path: "/account",
    name: "Account",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/Account.vue"),
    meta: {
      layout: "DashboardLayout",
      title: "Mon compte",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    meta: {
      layout: "CenterLayout",
      title: "S'enregistrer",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      layout: "CenterLayout",
      title: "Se connecter",
    },
  },
  {
    path: "/reset-password/request",
    name: "ResetPasswordRequest",
    component: () =>
      import(
        /* webpackChunkName: "reset_password_request" */ "../views/ResetPassword/Request.vue"
      ),
    meta: {
      layout: "CenterLayout",
      title: "Demande de réinitialisation de mot de passe",
    },
  },
  {
    path: "/reset-password/execute",
    name: "ResetPasswordExecute",
    component: () =>
      import(
        /* webpackChunkName: "reset_password_execute" */ "../views/ResetPassword/Execute.vue"
      ),
    meta: {
      layout: "CenterLayout",
      title: "Réinitialiser son mot de passe",
      resetPasswordCause: "reset",
    },
  },
  {
    path: "/activate",
    name: "ActivateAccount",
    component: () =>
      import(
        /* webpackChunkName: "activate_account" */ "../views/ResetPassword/Execute.vue"
      ),
    meta: {
      layout: "CenterLayout",
      title: "Activation de votre compte",
      resetPasswordCause: "activate",
    },
  },
  // {
  //   path: "/elevator/:id/documents",
  //   name: "ElevatorDocuments",
  //   component: () => import(/* webpackChunkName: "elevator_documents" */ "../views/Elevators/ElevatorDocuments.vue"),
  //   meta: {
  //     layout: 'DashboardLayout',
  //     title: "Documents d'un ascenseur"
  //   }
  // },
  {
    path: "/debug",
    name: "Debug",
    component: () =>
      import(/* webpackChunkName: "debug" */ "../views/Debug.vue"),
    meta: {
      layout: "DashboardLayout",
      title: "Debug",
    },
  },
  {
    path: "/legals",
    name: "Legals",
    component: () =>
      import(/* webpackChunkName: "legals" */ "../views/Legals.vue"),
    meta: {
      layout: "CenterLayout",
      title: "Mentions légales",
    },
  },
  {
    path: "/audits/:id/view",
    name: "ViewAudit",
    component: () =>
      import(
        /* webpackChunkName: "view_audit" */ "../views/Audits/ViewAudit.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      title: "Audit",
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: {
      layout: "CenterLayout",
      title: "Page non trouvée",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
