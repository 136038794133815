import genericItem from "./genericItem";

const base = genericItem("audits", "audit");

export default {
  namespaced: true,
  actions: {
    ...base.actions,
    async fetchDetails({ rootGetters }, id) {
      const apitator = rootGetters.apitatorInstance;

      const response = await apitator.get(`/audits/${id}/details`, {
        withAuth: true,
      });
      return response.data;
    },
    async endEditable({ rootGetters }, id) {
      const apitator = rootGetters.apitatorInstance;

      const response = await apitator.get(`/audit/editable/${id}`, {
        withAuth: true,
      });
      return response.data;
    },
    async updateAuditAnswers({ rootGetters }, { id, answers }) {
      const apitator = rootGetters.apitatorInstance;

      const response = await apitator.post(
        `/audit/${id}/update_answers`,
        answers,
        {
          withAuth: true,
        }
      );
      return response.data;
    },
  },
};
