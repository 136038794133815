<template>
  <v-card>
    <v-card-title>
      <span class="headline">
        Statistiques
      </span>
    </v-card-title>
    <v-card-text>
      <div
        v-if="loading">
        <v-progress-circular
          indeterminate
        />
      </div>
      <v-list v-else>
        <template v-for="(entity, i) in entities">
          <v-list-item
            :key="i + '_'"
          >
            <v-list-item-icon>
              <v-icon>{{ entity.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ entity.number }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ entity.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
const entitiesTemplate = [
  {
    id: 'companies',
    name: 'Entreprises',
    icon: 'mdi-domain'
  },
  {
    id: 'users',
    name: 'Utilisateurs',
    icon: 'mdi-account-circle'
  },
  {
    id: 'elevators',
    name: 'Ascenseurs',
    icon: 'mdi-elevator'
  },
  {
    id: 'sites',
    name: 'Sites',
    icon: 'mdi-hammer-screwdriver'
  }
]
export default {
  props: {
  },
  data: () => ({
    loading: false,
    entities: []
  }),
  created () {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$apitator.get(
        '/stats',
        { withAuth: true }
      ).then(res => {
        this.loading = false
        let data = res.data.data.entities_count
        this.entities = entitiesTemplate.map(({ name, id, icon }) => {
          return {
            id, name, icon, number: data[id] ?? 0
          }
        })
      })
    }
  }

}
</script>