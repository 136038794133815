import dataTableHelper from '../../utils/dataTableHelper'

export default function (ressourcePlural, ressourceSingular = null) {
  if (!ressourceSingular) {
    ressourceSingular = ressourcePlural.slice(0, -1)
  }
  return {
    namespaced: true,

    actions: {
      async fetchAll({ rootGetters }, options) {
        const apitator = rootGetters.apitatorInstance

        return await dataTableHelper.fetchData(apitator, `/${ressourcePlural}`, options)
      },
      async fetchOne({ rootGetters }, id) {
        const apitator = rootGetters.apitatorInstance

        const response = await apitator.get(`/${ressourcePlural}/${id}`, { withAuth: true })
        return response.data
      },
      async create({ rootGetters }, item) {
        const apitator = rootGetters.apitatorInstance

        const response = await apitator.post(`/${ressourcePlural}`, item, { withAuth: true });
        console.debug(`--> Created ${ressourceSingular}`, item)

        return response.data.id ?? true
      },
      async update({ rootGetters }, item) {
        const apitator = rootGetters.apitatorInstance

        const response = await apitator.put(`/${ressourcePlural}/` + item.id, item, { withAuth: true });
        console.debug(`--> Updated ${ressourceSingular} to`, item)

        return true
      },
      async removeSpecial({ rootGetters }, item) {
        const apitator = rootGetters.apitatorInstance

        await apitator.delete(`/${ressourcePlural}/` + item.id + '/special', { withAuth: true });
        console.debug(`--> Removed ${ressourceSingular} with special method`, item)
      },
      async remove({ rootGetters }, item) {
        const apitator = rootGetters.apitatorInstance

        await apitator.delete(`/${ressourcePlural}/` + item.id, { withAuth: true });
        console.debug(`--> Removed ${ressourceSingular}`, item)
      },
    }
  }
}
