import { DateTime } from 'luxon'

export default {
  fetchData: async (apitator, resource, config = null) => {
    config = {
      sortBy: ['created_at'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 100,
      ...config
    }
    let { sortBy, sortDesc, page, itemsPerPage } = config
    let orderParams = {}
    orderParams['order[' + (sortBy[0] ?? 'createdAt') + ']'] =
        (sortDesc[0] ?? true) ? 'desc' : 'asc'

    let params = {
      itemsPerPage,
      ...config.additionalParams,
      ...orderParams,
      page,
    }
    if (itemsPerPage < 0) {
      // we should use the vanilla fetch api
      delete params.itemsPerPage
      delete params.page
      params.pagination = 'false'
    }

    //console.log(JSON.parse(JSON.stringify(config)), fetchMode)
    const response = await apitator.get(resource, {
      withAuth: true,
      params,
      headers: {
        'Accept': 'application/ld+json'
      }
    })
    const data = response.data

    return {
      totalItems: data['hydra:totalItems'],
      member: data['hydra:member'].map(item => {
        if (item.createdAt) {
          const createdAt = DateTime.fromISO(item.createdAt)
          return {
            ...item,
            createdAtObject: createdAt,
            createdAtAsInt: createdAt.valueOf(),
          }
        }
        return item
      })
    }
  }
}
