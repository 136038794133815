import genericItem from "./genericItem";

const base = genericItem("audit_answers", "audit_answer");

export default {
  namespaced: true,
  actions: {
    ...base.actions,
    async fetchByAudit({ rootGetters }, auditId) {
      const apitator = rootGetters.apitatorInstance;
      const response = await apitator.get(`/audit_answers`, {
        withAuth: true,
        params: { audit: auditId},
      });
      console.log("Response => ", response);
      return response.data;
    },
  },
};
