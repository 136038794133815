const KEY_PREFIX = 'tatchi_extranet_';

export default {
  set(key, value) {
    localStorage.setItem(KEY_PREFIX + key, JSON.stringify(value));
  },
  remove(key) {
    localStorage.removeItem(KEY_PREFIX + key);
  },
  get(key) {
    const value = localStorage.getItem(KEY_PREFIX + key);
    return value ? JSON.parse(value) : null;
  }
}