
const availableStatues = [
  { id: 'pending', name: "En attente d'utilisation" },
  { id: 'being-used', name: "En utilisation" },
  { id: 'used', name: "Fin d'utilisation" },
]


const availableCompanyTypes = [
  {
    id: 'FINAL_CLIENT',
    key: 'finalClient',
    name: "Maître d'ouvrage",
    icon: 'mdi-domain'
  },
  {
    id: 'ASCENSORIST',
    key: 'ascensorist',
    name: "Ascensoriste",
    icon: 'mdi-factory'
  },
  {
    id: 'AMO',
    key: 'amo',
    name: "Assistant à Maître d'ouvrage (AMO)",
    icon: 'mdi-map'
  },
]

export { availableStatues, availableCompanyTypes }