import genericItem from './genericItem'

const base = genericItem('product_sheets', 'product_sheets')

export default {
  namespaced: true,
  actions: {
    ...base.actions,
    // Check if productSheet is already existing by its ref
    async createProductSheet({ rootGetters }, params) {
      const apitator = rootGetters.apitatorInstance

      const response = await apitator.post(`/create/productSheet`, params.formData, { withAuth: true, params: params.formData, headers: { "Content-Type": 'multipart/form-data; boundary=<calculated when request is sent>', 'Accept': '*/*' }});

      return response.data.id ?? true
    },
  }
}
