import dataTableHelper from '../../utils/dataTableHelper'

export default {
  namespaced: true,

  actions: {
    async fetchAllVanilla({ rootGetters }) {
      const apitator = rootGetters.apitatorInstance

      const res = await apitator.get('/waste_types', { withAuth: true })
      return res.data
    },
    async fetchAll({ rootGetters }, options) {
      const apitator = rootGetters.apitatorInstance

      return await dataTableHelper.fetchData(apitator, '/waste_types', options)
    },
    async fetchOne({ rootGetters }, id) {
      const apitator = rootGetters.apitatorInstance

      const response = await apitator.get('/waste_types/' + id, { withAuth: true })
      return response.data
    },
    async create({ rootGetters }, waste_type) {
      const apitator = rootGetters.apitatorInstance

      const response = await apitator.post('/waste_types', waste_type, { withAuth: true });
      console.debug('--> Created waste_type', response.data)
      
      return true
    },
    async update({ rootGetters }, waste_type) {
      const apitator = rootGetters.apitatorInstance

      const response = await apitator.put('/waste_types/' + waste_type.id, waste_type, { withAuth: true });
      console.debug('--> Updated waste_type', response.data)

      return true
    },
    async remove({ rootGetters }, waste_type) {
      const apitator = rootGetters.apitatorInstance

      await apitator.delete('/waste_types/' + waste_type.id, { withAuth: true });
      console.debug('--> Removed waste_type', waste_type)
    },
  },
}
