<template>
  <v-app id="inspire" theme="dark">
    <component :is="$route.meta.layout" ref="layoutChild" />

    <v-footer v-if="false && $route.meta.layout === 'CenterLayout'" app bottom>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} - Socobat
      </v-col>
    </v-footer>
    <SnackbarStack ref="snackbarStack" />
  </v-app>
</template>

<script>
import CenterLayout from "./layouts/CenterLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import SnackbarStack from "./components/SnackbarStack.vue";

export default {
  components: { DashboardLayout, CenterLayout, SnackbarStack },
  data: () => ({
    authVerified: false,
  }),
  created() {
    console.log("APP: Using env", {
      API_BASE: process.env.VUE_APP_API_BASE,
      MERCURE_HUB: process.env.VUE_APP_MERCURE_HUB,
      MERCURE_TOPIC: process.env.VUE_APP_MERCURE_TOPIC,
    });

    this.$store.commit("setApitatorInstance", this.$apitator);
    this.$store.dispatch("init");
    this.$store.dispatch("auth/init").catch((error) => {
      console.error("login action error", error);
      console.error("token probably expired or is invalid");
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "Login" });
      this.$store.commit("alert/add", {
        type: "error",
        message: "Votre session a expiré, veuillez vous reconnecter.",
      });
    });

    console.log("APP: Debug mode:", this.$store.state.debugMode);

    window.enableDebug = () => {
      this.$store.commit("setDebugMode", true);
      return true;
    };

    window.disableDebug = () => {
      this.$store.commit("setDebugMode", false);
      return true;
    };

    window.getDebug = () => {
      return this.$store.state.debugMode;
    };

    window.getAuthToken = () => {
      return this.$store.state.auth.token;
    };

    window.getDebugInformation = () => {
      return JSON.stringify({
        token: this.$store.state.auth.token,
        user: this.$store.state.auth.user,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        debugMode: this.$store.state.debugMode,
        apiBase: process.env.VUE_APP_API_BASE,
        navigator: {
          uA: navigator.userAgent,
          language: navigator.language,
          cookies: navigator.cookieEnabled,
          memory: navigator.deviceMemory,
          maxTouchPoints: navigator.maxTouchPoints,
        },
      });
    };

    window.gdi = window.getDebugInformation;

    window.testAlert = (type, message, timeout) => {
      this.$store.commit("alert/add", {
        type: type ?? "success",
        message: message ?? "test alert",
        timeout: timeout ?? 3000,
      });
    };

    window.toggleVuetifyTheme = () => {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    };

    this.$vuetify.theme.dark = this.$store.state.theme === "dark";
  },
  mounted() {
    this.$store.commit("alert/setInterface", this.$refs.snackbarStack);
  },
  watch: {
    "$route.meta.title"(title) {
      this.$store.commit("setTitle", title);
    },
    "$store.state.auth.isInitialized"() {
      this.verifyAuth();
    },
    $route() {
      this.verifyAuth();
    },
  },
  methods: {
    verifyAuth() {
      if (!this.$route.meta.layout) {
        return;
      }
      if (
        !this.authVerified &&
        this.$store.state.auth.isInitialized &&
        !this.$store.state.auth.isAuthenticated &&
        this.$route.meta.layout === "DashboardLayout"
      ) {
        this.$router.replace({ name: "Login" });
        this.$store.commit("alert/add", {
          color: "error",
          text: "Vous devez être connecté pour accéder à cette page.",
        });
      }
      this.authVerified = true;
    },
  },
};
</script>
